<template>
    <div class="p-2">
        
        <!-- USER HEADER INFO -->
        <div class="d-flex align-items-center" style="gap:10px 20px" v-if="user">
            <div>
                <img :src="user.user_picture"
                    class="rounded-circle" style="height:80px;width:80px;border:2px solid white" />
            </div>
            <div v-if="user">
                <h3 class="text-white h4 mb-0">{{user.user_name}}</h3>
                <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{ user.user_phone }}</h3>
                <h3 class="text-white h4 mb-0">{{user.user_location}}</h3>
            </div>
            <div class="ml-auto">
                <router-link to="/profile/form">
                    <i class="text-white bx bx-edit" style="font-size: 28px;cursor: pointer"></i>
                </router-link>
            </div>
        </div>
        <!-- END USER HEADER INFO -->

        <hr style="border-color: #FFAD32;" />

        <!-- GENERAL -->
        <div class="mt-3">
            <h2 class="h3 text-white font-weight-bolder mb-0 pr-1" style="padding-bottom:.5em;border-bottom: 1px solid #FFAD32;width: fit-content;">General</h2>
        </div>
        
        <router-link to="/profile/form" class="d-flex align-items-center h4 mt-1 font-weight-normal" style="gap:10px">
            <div class="text-white flex-fill">My Account</div>
            <i class="bx bx-chevron-right text-white" style="font-size: 18px;"></i>
        </router-link>
        
        <router-link to="/activities" class="d-flex align-items-center h4 mt-1 font-weight-normal" style="gap:10px">
            <div class="text-white flex-fill">My Activities</div>
            <i class="bx bx-chevron-right text-white" style="font-size: 18px;"></i>
        </router-link>
        
        <!-- <div class="d-flex align-items-center h4 mt-1 font-weight-normal" style="gap:10px">
            <div class="text-white flex-fill">Password</div>
            <i class="bx bx-chevron-right text-white" style="font-size: 18px;"></i>
        </div> -->
        
        <!-- <div class="d-flex align-items-center h4 mt-1 font-weight-normal" style="gap:10px">
            <div class="text-white flex-fill">PIN</div>
            <i class="bx bx-chevron-right text-white" style="font-size: 18px;"></i>
        </div> -->

        <div @click="logout()" class="d-flex align-items-center h4 mt-1 font-weight-normal" style="gap:10px">
            <div class="text-white flex-fill">Logout</div>
            <i class="bx bx-chevron-right text-white" style="font-size: 18px;"></i>
        </div>
        <!-- END GENERAL --> 

        <!-- BUSSINESS -->
        <div class="mt-3">
            <h2 class="h3 text-white font-weight-bolder mb-0 pr-1" style="padding-bottom:.5em;border-bottom: 1px solid #FFAD32;width: fit-content;">Bussiness</h2>
        </div>
        
        <router-link to="bussiness" class="d-flex align-items-center h4 mt-1 font-weight-normal" style="gap:10px">
            <div class="text-white flex-fill">Mongrela Bussiness</div>
            <i class="bx bx-chevron-right text-white" style="font-size: 18px;"></i>
        </router-link>

        <!-- LEGAL -->
        <!-- <div class="mt-3">
            <h2 class="h3 text-white font-weight-bolder mb-0 pr-1" style="padding-bottom:.5em;border-bottom: 1px solid #FFAD32;width: fit-content;">Terms & Condition</h2>
        </div>  -->
        <!-- END LEGAL -->

        <!-- FEEDBACK -->
        <!-- <div class="mt-3">
            <h2 class="h3 text-white font-weight-bolder mb-0 pr-1" style="padding-bottom:.5em;border-bottom: 1px solid #FFAD32;width: fit-content;">Feedback</h2>
        </div>  -->
        <!-- END FEEDBACK -->

    </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'

import store from '@/store'

export default {
    components: {
    BNavItemDropdown,
    BDropdownItem,
},
    methods: {
        logout(){
            store.dispatch('auth/Logout')
        }
    },
    computed: {
        user(){
            return store.state.auth.USER
        }
    }
}

</script>